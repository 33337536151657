import { Global, css } from '@emotion/react';
import { Fragment } from 'react';
import { useProxy } from 'valtio/utils';
import { createDesktopCss } from '~/css/createDesktopCss';
import { createMobileAndIPadCss } from '~/css/createMobileAndIPadCss';
import { ComponentBoundary } from '~/modules/SDK/FrError/components/ComponentBoundary';
import { FrTemplate } from '~/modules/SDK/FrTemplate/v2/FrTemplate';
import { DataProvider } from '~/pages/strategy_center_admin/_components/DataProvider';
import { PageLoginForm } from '~/pages/strategy_center_admin/_components/PageLoginForm';
import { PageSidebar } from '~/pages/strategy_center_admin/_components/PageSidebar';
import { SidebarHeader } from '~/pages/strategy_center_admin/_components/SidebarHeader';
import { pageCssset } from '~/pages/strategy_center_admin/pageCssset';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
export const PageLayout = (props) => {
    const areas = FrTemplate.getAreasName('NextPageRootLayout');
    const drawer1 = useProxy(pageStore.drawer1);
    const me = useProxy(pageStore.me);
    const modal = useProxy(pageStore.modalStore);
    const modal2 = useProxy(pageStore.modal2Store);
    return (<ComponentBoundary filepath='pages/strategy_center_admin/_components/PageLayout.tsx'>
      <FrTemplate.LayoutRebase202309>
        <Fragment>
          <Global styles={pageCssset}/>

          <modal.ModalContainer />
          <modal2.ModalContainer />

          {!me.hasLogin && <PageLoginForm />}

          {me.hasLogin && (<DataProvider>
              <FrTemplate.GridRoot name={areas.Root} css={[
                css `
                    place-content: flex-start;

                    ${createDesktopCss(css `
                      grid-template-rows: 48px 1fr 1fr;
                      grid-template-columns: 200px 1fr 1fr;

                      grid-template-areas:
                        '${areas.Drawer1} ${areas.Main} ${areas.Main}'
                        '${areas.Drawer1} ${areas.Main} ${areas.Main}'
                        '${areas.Drawer1} ${areas.Main} ${areas.Main}';
                    `)}

                    ${createMobileAndIPadCss(css `
                      grid-template-rows: 48px 1fr 1fr;
                      grid-template-columns: 200px 1fr 1fr;

                      grid-template-areas:
                        '${areas.Topbar} ${areas.Topbar} ${areas.Topbar}'
                        '${areas.Main} ${areas.Main} ${areas.Main}'
                        '${areas.Main} ${areas.Main} ${areas.Main}';
                    `)}
                  `,
            ]}>
                <FrTemplate.MediaRender isPhone>
                  <FrTemplate.GridArea name={areas.Topbar}>
                    <SidebarHeader />
                  </FrTemplate.GridArea>

                  <FrTemplate.GridDrawer drawer={drawer1} css={Drawer1Css}>
                    <PageSidebar />
                  </FrTemplate.GridDrawer>
                </FrTemplate.MediaRender>

                <FrTemplate.MediaRender isPc>
                  <FrTemplate.GridArea name={areas.Drawer1} css={Drawer1Css}>
                    <PageSidebar />
                  </FrTemplate.GridArea>
                </FrTemplate.MediaRender>

                <FrTemplate.GridArea name={areas.Main}>
                  <props.MainContent />
                </FrTemplate.GridArea>
              </FrTemplate.GridRoot>
            </DataProvider>)}
        </Fragment>
      </FrTemplate.LayoutRebase202309>
    </ComponentBoundary>);
};
const Drawer1Css = css `
  padding: 0 8px;
`;
