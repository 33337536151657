import { Box, Collapse, Group, ThemeIcon, UnstyledButton, rem } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { Fragment, useState } from 'react';
import { clas as cx } from '~/utils/clas';
import * as classes from './SidebarLinks.module.scss';
import { AppLink2 } from '~/components/AppLink2';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
export function SidebarLinks({ icon: Icon, label, initiallyOpened, links, }) {
    const hasLinks = Array.isArray(links);
    const [opened, setOpened] = useState(initiallyOpened || false);
    const items = (hasLinks ? links : []).map(link => {
        const shouldHidden = link.functionHidden?.includes(fr_agents.agentProduct);
        if (shouldHidden) {
            return null;
        }
        return (<AppLink2 className={classes.link} href={link.link} key={link.label}>
        {link.label}
      </AppLink2>);
    });
    return (<Fragment>
      <UnstyledButton onClick={() => {
            setOpened(o => !o);
        }} className={cx(classes.control)}>
        <Group className={cx(classes.group)}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <ThemeIcon variant='light' size={30}>
              <Icon style={{ width: rem(18), height: rem(18) }}/>
            </ThemeIcon>
            <Box ml='md'>{label}</Box>
          </Box>
          {hasLinks && (<IconChevronRight className={classes.chevron} stroke={1.5} style={{
                width: rem(16),
                height: rem(16),
                transform: opened ? 'rotate(-90deg)' : 'none',
            }}/>)}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </Fragment>);
}
