import { css } from '@emotion/react';
import { Code, ScrollArea, } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import { useProxy } from 'valtio/utils';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { SidebarLinks } from '~/pages/strategy_center_admin/_components/SidebarLinks/SidebarLinks';
import { pageConfig } from '~/pages/strategy_center_admin/pageConfig';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
import { clas as cx } from '~/utils/clas';
import * as classes from './PageSidebar.module.scss';
import { ENV } from '~/configs/ENV';
import styled from '@emotion/styled';
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss';
PageSidebar.classes = classes;
export function PageSidebar(props) {
    const me = useProxy(pageStore.me);
    const links = pageConfig.sidebarItems.map(link => {
        return (<SidebarLinks {...link} key={link.label}/>);
    });
    return (<classess.container>
      <div className={cx(classes.header)}>
        <div className={cx(classes.logo)}>
          <img className={cx(classes.logoImg)} src={fr_agents.config.favicon}/>
        </div>
        <Code fw={700}>{ENV.DATETIME.substring(0, 10)}</Code>
      </div>
      <ScrollArea className={cx(classes.links)}>
        <div className={cx(classes.linksInner)}>{links}</div>
      </ScrollArea>
      <classess.logout.body onClick={event => {
            me.logout();
        }}>
        <IconLogout color='#228be6' size={24} css={css `
            cursor: pointer;
          `}/>
        <classess.logout.text>登出</classess.logout.text>
      </classess.logout.body>
    </classess.container>);
}
const classess = {
    container: styled.div `
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-rows: 100px 1fr 60px;
    background-color: #fdfdfd;
  `,
    logout: {
        body: styled.div `
      ${fill_horizontal_cross_center};
      height: 40px;
      gap: 16px;
      cursor: pointer;
      &:hover {
        background-color: #e8e8e8;
        border-radius: 4px;
      }
    `,
        text: styled.span `
      font-weight: 600;
    `,
    },
};
